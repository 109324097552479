import * as React from "react";
import { useState } from "react";
import { languages } from "./data";
import { Link } from "gatsby";
import QuotePopUp from "../../components/popup/quotePopUp/index";
import ContactusMobile from "components/reusableComponents/contactusMobile/ContactusMobile";
import arrowDownSvg from "assets/icons/iconDownWhite.svg";

const RelativeLanguages = ({ open, setOpen, forHebrew }) => {
  const [openContactusMobileTwo, setOpenContactusMobileTwo] = useState(false);

  return (
    <div className="bg-banner2-ads bg-no-repeat bg-cover">
      <div className="py-10 sm:py-20 relative 2xl:px-0 max-w-5xl mx-auto">
        <div className="flex xs:flex-col lg:flex-row items-start gap-3 xl:gap-32 ">
          <div className="flex flex-col ">
            <h2
              className={` ${
                forHebrew ? "w-full text-rtl" : "max-w-2xl"
              } text-[35px] sm:text-[40px] font-bold font-secondary text-[#00173A] my-5 px-10 sm:pr-0 sm:w-[35rem] sm:absolute right-0 top-0 sm:mt-14`}
            >
              120 שפות ומעל 950 צמדי שפות
            </h2>
            <div
              className={` ${
                forHebrew
                  ? "flex flex-col md:flex-row-reverse"
                  : "flex md:flex xs:flex-col lg:flex-row xl:gap-10"
              }  items-baseline relative sm:pt-20`}
            >
              <div className="max-w-xl xl:max-w-2xl ">
                <p className=" text-[16px] sm:text-lg font-regular font-opensans pt-1 text-[#00173A] px-10 sm:pr-0 md:pl-8 text-right text-rtl">
                  אין מה לדאוג, יש לנו כיסוי לכל שפה אנו מעניקים שירות בכל השפות
                  הנפוצות ביותר לתרגום וגם בשפות נדירות יותר. אתם מוזמנים לברר
                  עם מנהלי הלקוחות שלנו את רשימת השפות המלאה.
                </p>

                <button
                  onClick={() => setOpenContactusMobileTwo(true)}
                  className="h-[52px] mx-auto gap-x-4 w-[275px] sm:w-[208px] text-base text-center font-opensans font-semibold text-white bg-orange hover:bg-[#FF8F3A] rounded-full cursor-pointer flex items-center justify-center mt-7 mb-5 self-start sm:hidden px-10 hover:bg-yellowHover transition-colors relative"
                >
                  צרו קשר
                  <img
                    src={arrowDownSvg}
                    alt="arrow down"
                    className={`${
                      openContactusMobileTwo ? "visible" : "invisible absolute"
                    } `}
                  />
                </button>
                <button
                  onClick={() => setOpen(true)}
                  className="h-[52px] hidden w-full sm:w-[208px] text-base text-center font-opensans font-semibold text-white bg-orange hover:bg-[#FF8F3A] rounded-full cursor-pointer sm:inline-flex items-center justify-center mx-auto absolute right-0 mt-7 mb-5 self-start px-10 hover:bg-yellowHover transition-colors "
                >
                  צרו קשר
                </button>
              </div>
              <div className="absolute left-0 top-[14rem]">
                <ContactusMobile
                  open={openContactusMobileTwo}
                  setOpen={setOpenContactusMobileTwo}
                  blueTheme
                  noPlaceholder
                  // forHebrew={forHebrew}
                />
              </div>

              <div
                className={`flex flex-col sm:px-28  2xl:max-w-sm px-10 md:px-8 ${
                  openContactusMobileTwo && "pt-[38rem] "
                }`}
              >
                <ul className="grid gap-y-5 pt-6 pb-12 grid-column-end grid-cols-2 gap-x-24">
                  {languages.map((item, i) => (
                    <li key={i} className="flex flex-row-reverse gap-3 items-center">
                      <img
                        src="https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg"
                        alt="check"
                        className="w-6 h-5"
                      />
                      <Link
                        to={item.hrefLink}
                        className="text-base font-opensans text-blue cursor-pointer hover:text-yellowHover transition-colors"
                      >
                        {item.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <QuotePopUp open={open} setOpen={setOpen} />
    </div>
  );
};

export default RelativeLanguages;
