import React from "react";
import Checked from "assets/CheckedIcon.svg";

const CarOneNew = () => {
  return (
    <div className="bottoM w-full sm:w-10/12 flex flex-col justify-center mx-auto ">
      <div className="flex sm:flex-row flex-col gap-x-6 justify-between pt-[4rem]">
        <div className="text-center">
          <img src={Checked} alt="" className="mx-auto pb-2" />
          <h3 className="text-[20px] font-semibold text-[#00173A] font-secondary">
            אחריות לשנה על כל <br />
            עבודה
          </h3>
          <p className="text-[15px] font-opensans text-[#00173A] leading-loose pt-8">
            כל עבודות התרגום שלנו מגיעות <br />
            עם התחייבות לתמיכה במשך שנה, <br />
            במקרה שמתגלה סוגיית איכות הדורשת <br />
            תיקונים.
          </p>
        </div>
      </div>
    </div>
  );
};
export default CarOneNew;
