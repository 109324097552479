import SecondAdComp from "components/homepage/Ads-Page/secondAdComp";
import HomepageReviews from "components/homepage/homepageReviews";
import ReviewCards from "components/homepage/reviewcard";
import ReviewCardsDesign2 from "components/homepage/reviewcard/design2";
import React from "react";
import { SecondNewData } from "../homepage/Ads-Page/config";
import Services from "./Services";
import { ServicesData,ServicesData2 } from "components/LP/data";
import GroupVector from "assets/groupVector.png"

const ReviewSection = ({open, setOpen, heading, data, data2, forHebrew}) => {
  return (
    <div className="bg-gradient-to-b from-white to-[#E8F1F5]">
      <Services
        background="bg-none"
        heading={heading}
        servicesdata={data ? data : ServicesData}
        servicesdata2={data2 ? data2 :ServicesData2}
        paragraphText="אנחנו בטומדס מתגאים במגוון הרחב של שירותי התרגום שאנו מציעים. המתרגמים המומחים שלנו הם בעלי ניסיון עשיר בתרגום של מגוון סוגי מסמכים. להלן כמה מההתמחויות שלנו:"
        btn="צרו קשר"
        addImag={GroupVector}
        open={open} setOpen={setOpen}
        forHebrew={forHebrew}
      />
      <SecondAdComp
        data={SecondNewData}
        midContent
        bgCol="bg-none"
        newCarousel
        title="מה טומדס מבטיחה לספק לכם?"
        fontBold
        headTitle="מה טומדס מבטיחה לספק לכם?"
        NewCards
        customWidthPara="w-[239px]"
        parentDivwidth
        gapOn2xl
        forHebrew={forHebrew}
      />
    </div>
  );
};

export default ReviewSection;
