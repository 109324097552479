import React from "react";
import Stars from "assets/ads-assets/stars.svg";
import AdsCarousel from "./carosule";
import GrowthCarousel from "./growthCarousel";
import NewGrowthCarousel from "components/LP/newGrowthCarousel";

const SecondAdComp = ({
  data,
  TopSection,
  paddingTop,
  BottomSection,
  bgCol,
  newCarousel,
  title,
  fontBold,
  headTitle,
  NewCards,
  midContent,
  parentDivwidth,
  customWidth,
  customWidthPara,
  fudduWidth,
  NotshowCarousel,
  noContent,
  gapX,
  noPadding,
  gapOn2xl,
  forHebrew,
}) => {
  return (
    <div className={`${bgCol ? bgCol : "bg-white"} py-16`}>
      <div className="flex flex-col ">
        {TopSection ? (
          <div className="flex flex-wrap mx-auto justify-between w-full sm:w-10/12 px-2 2xl:w-7/12">
            <div className="left w-full sm:w-[485px]">
              <h2 className="sm:text-[40px] text-[#00173A] text-[35px] font-bold font-secondary sm:text-left text-center">
                <span className="text-orange">4.9 out of 5</span> <br />{" "}
                Aggregate Rating
              </h2>
              <p className="text-[18px] sm:text-[25px] font-secondary text-[#00173A] pt-4 sm:text-left text-center">
                (Out of 1798 Verified Reviews Online)
              </p>
              <img src={Stars} alt="" className="py-6" />
              <p className="text-[18px] text-[#00173A] font-opensans">
                Tomedes takes pride in 95,000+ satisfied customers, around the
                globe, a number that includes several Fortune 500 companies. We
                have a 4.9 out of 5 aggregate star rating from 1,798 online
                reviews.
              </p>
            </div>

            <AdsCarousel />
            {/* -------Carousel------- */}
          </div>
        ) : null}

        {midContent ? (
          <div
            className={`bottoM w-full  lg:w-11/12 sm:flex flex-col justify-center mx-auto ${
              paddingTop ? "pt-[10rem]" : null
            } 2xl:w-9/12 3xl:w-7/12 hidden`}
          >
            <h2
              className={`text-[26px] text-rtl sm:text-[40px] font-secondary text-[#00173A] flex justify-center mx-auto text-center w-9/12 ${
                fontBold ? "font-bold" : null
              }`}
            >
              {title ? title : "What Makes Tomedes Your Growth Partner?"}
            </h2>
            <div
              className={`flex md:flex-row flex-col gap-x-4 ${
                gapOn2xl ? "2xl:gap-x-28" : null
              }  ${
                gapX ? "2xl:gap-x-16 lg:gap-x-[5.5rem]" : null
              } justify-between pt-[4rem] ${parentDivwidth ? "m-auto" : null}`}
            >
              {data
                ? data.map(({ index, head, content, picture, head2 }) => (
                    <div
                      className={`text-center ${
                        customWidth ? customWidth : null
                      }`}
                      key={index}
                    >
                      <img src={picture} alt="" className="mx-auto pb-4" />
                      <h3 className="text-[20px] font-semibold text-[#00173A] font-secondary">
                        {head} <br /> {head2}
                      </h3>
                      <p
                        className={`${
                          customWidthPara ? customWidthPara : null
                        } text-[15px] font-opensans text-[#00173A] leading-loose pt-8 text-rtl`}
                      >
                        {content}
                      </p>
                    </div>
                  ))
                : null}
            </div>
          </div>
        ) : null}

        {NotshowCarousel ? null : (
          <div className={`sm:hidden ${noPadding ? null : "pt-[10rem]"} flex`}>
            {newCarousel ? (
              <NewGrowthCarousel NewCards={NewCards} headTitle={headTitle} />
            ) : (
              <GrowthCarousel />
            )}
          </div>
        )}

        {BottomSection ? (
          <div
            className={` ${
              forHebrew ? "flex-row-reverse" : ""
            } flex flex-wrap mx-auto justify-between w-full sm:w-10/12 px-2 2xl:w-8/12 3xl:w-7/12 pt-[4rem]`}
          >
            <div className="left w-full sm:w-[485px] px-4">
              {forHebrew ? (
                <h2 className="sm:text-[40px] text-right text-[#00173A] text-[35px] font-bold font-secondary ">
                לקוחות ממליצים עלינו
                </h2>
              ) : (
                <>
                  <h2 className="sm:text-[40px] text-[#00173A] text-[35px] font-bold font-secondary sm:text-left text-center">
                    <span className="text-orange">4.9 out of 5</span> <br />
                    Aggregate Rating
                  </h2>
                  <p className="text-[18px] sm:text-[25px] font-secondary text-[#00173A] pt-4 sm:text-left text-center">
                    (Out of 1798 Verified Reviews Online)
                  </p>
                </>
              )}
              <img
                src={Stars}
                alt=""
                className={` ${forHebrew ? "mr-0" : "sm:m-0"} py-6 m-auto `}
              />
              {noContent ? null : (
                <p
                  className={`text-[18px] text-[#00173A] font-opensans text-rtl ${
                    forHebrew ? "text-right" : ""
                  }`}
                >
                טומדס מתגאה במעל 95 אלף לקוחות עסקיים מרוצים מסביב לעולם. חלק מהחברות הללו הן מרשימת פורצ'ן 500. יש לנו דירוג של 4.9 מתוך 5 כוכבים בקרב 1798 ביקורות אונליין.
                </p>
              )}
            </div>

            <AdsCarousel />
            {/* -------Carousel------- */}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SecondAdComp;
