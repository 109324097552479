import React from "react";
import first from "assets/tickGroup.svg";

const CarOne = () => {
  return (
    <div className="bottoM w-full sm:w-10/12 flex flex-col justify-center mx-auto ">
      <div className="flex sm:flex-row flex-col gap-x-6 justify-between pt-[4rem]">
        <div className="text-center">
          <img src={first} alt="" className="mx-auto pb-2" />
          <h3 className="text-[20px] font-semibold text-[#00173A] font-secondary">
            מוניטין עשיר בהצלחות
          </h3>
          <p className="text-[15px] font-opensans text-[#00173A] leading-loose pt-8 text-rtl">
            מוניטין עשיר בהצלחות חברות ענק כמו גוגל
            <br />
            עבדו איתנו, בחנו אותנו ויצאו מרוצות. עבודות
            <br />
            התרגום שלכם תתבצענה באותה השקדנות
            <br />
            והקפדנות כדי להבטיח איכות גבוהה.
          </p>
        </div>
      </div>
    </div>
  );
};
export default CarOne;
