import React from "react";
import GroupIcon from "assets/GroupIcon.svg";

const CarThreeNew = () => {
  return (
    <div className="bottoM w-full sm:w-10/12 flex flex-col justify-center mx-auto ">
      <div className="flex sm:flex-row flex-col gap-x-6 justify-between pt-[4rem]">
        <div className="text-center">
          <img src={GroupIcon} alt="" className="mx-auto pb-2" />
          <h3 className="text-[20px] font-semibold text-[#00173A] font-secondary">
            צוות מסור המיועד <br />
            עבורכם בלבד
          </h3>
          <p className="text-[15px] font-opensans text-[#00173A] leading-loose pt-8">
            התרגום שלכם ינוהל על ידי צוות <br />
            מומחים ייעודי, תקבלו מנהל לקוחות <br />
            צמוד שיעמוד איתכם <br />
            ויוודא שכל הצרכים שלכם <br />
            בעבודה זוכים למענה. 
          </p>
        </div>
      </div>
    </div>
  );
};
export default CarThreeNew;
