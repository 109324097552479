import first from "assets/ads-assets/customer.svg";
import second from "assets/ads-assets/98.svg";
import third from "assets/ads-assets/ISO.svg";
import fourth from "assets/ads-assets/fast.svg";
import ShareIcon from "assets/tickGroup.svg";
import Headphones from "assets/wifiGroup.svg";
import Globe from "assets/groupPeople.png";
import CheckedIcon from "assets/CheckedIcon.svg";
import HeadphoneIcon from "assets/Headphones.svg";
import GroupPeople from "assets/GroupIcon.svg";

export const Data = [
  {
    picture: first,
    head: "95,000 ",
    head2: "Business Customers",
    content:
      "Tomedes is delighted to count over 95,000 businesses worldwide among our clients. Our years of dedication and commitment to every project has led us to work hand-in-hand with Fortune 500 companies as well as small-to-medium businesses all around the globe.",
  },
  {
    picture: second,
    head: "98% Customer",
    head2: "Satisfaction Rate",
    content:
      "Tomedes’ exceptional reputation is built on years of exceptional services and high-quality solutions for all types of corporate translation needs around the world.",
  },
  {
    picture: third,
    head: "ISO Certified ",
    head2: "Providing High Quality",
    content:
      "As an ISO-certified translation provider, you can be sure that your project is of the highest level of quality assurance and care. At Tomedes you have 3 possibilities: ISO 17100:2015 for translation quality, ISO 9001:2015 for quality management systems, and ISO 18587:2017 for machine translation post editing.",
  },
  {
    picture: fourth,
    head: "Fast ",
    head2: "Delivery",
    content:
      "Do you have an urgent project? No worries! Tomedes guarantees fast, dependable delivery for translations of any size or complexity. Just sit back, relax, and let us do the work.",
  },
];

export const NewData = [
  {
    picture: ShareIcon,
    head: "מוניטין עשיר בהצלחות",
    // head2: "Business Customers",
    content:
      "מוניטין עשיר בהצלחות חברות ענק כמו גוגל ואמזון עבדו איתנו, בחנו אותנו ויצאו מרוצות. עבודות התרגום שלכם תתבצענה באותה השקדנות והקפדנות כדי להבטיח איכות גבוהה.",
  },
  {
    picture: Headphones,
    head: "מגוון תחומי התמחות",
    // head2: "Satisfaction Rate",
    content:
      "המתרגמים שלנו הם בעלי ניסיון מגוון ומתמחים בעבודה מול תעשיות רבות. אתם תקבלו מאיתנו את אנשי המקצוע המתאימים ביותר לעבודת התרגום שלכם. מענה מותאם אישית כל צורך.",
  },
  {
    picture: Globe,
    head: "חווית השירות הטובה ביותר",
    // head2: "Providing High Quality",
    content:
    "חווית השירות הטובה ביותר בטומדס, הלקוחות נמצאים במרכז תשומת הלב שלנו מההתחלה ועד הסוף. התוצאות בהתאם: 98% מהלקוחות שלנו שבעי רצון."
  },
];

export const SecondNewData = [
  {
    picture: CheckedIcon,
    head: "אחריות לשנה על כל",
    head2: "עבודה",
    content:
      "כל עבודות התרגום שלנו מגיעות עם התחייבות לתמיכה במשך שנה, במקרה שמתגלה סוגיית איכות הדורשת תיקונים.",
  },
  {
    picture: HeadphoneIcon,
    head: "שירות לקוחות זמין",
    head2: "24/7",
    content:
      "הצוות המסור שלנו עובד מסביב לשעון. אנו זמינים לענות לפנייתכם תוך דקות ספורות.",
  },
  {
    picture: GroupPeople,
    head: "צוות מסור המיועד",
    head2: "עבורכם בלבד",
    content:
      "התרגום שלכם ינוהל על ידי צוות מומחים ייעודי, תקבלו מנהל לקוחות צמוד שיעמוד איתכם בקשר ויוודא שכל הצרכים שלכם בעבודה זוכים למענה.",
  },
];
