import React from "react";
import second from "assets/wifiGroup.svg";

const CarTwo = () => {
  return (
    <div className="bottoM w-full sm:w-10/12 flex flex-col justify-center mx-auto ">
      <div className="flex sm:flex-row flex-col gap-x-6 justify-between pt-[4rem]">
        <div className="text-center">
          <img src={second} alt="" className="mx-auto pb-2" />
          <h3 className="text-[20px] font-semibold text-[#00173A] font-secondary">
            מגוון תחומי התמחות
          </h3>
          <p className="text-[15px] font-opensans text-[#00173A] leading-loose pt-8 text-rtl">
          המתרגמים שלנו הם בעלי ניסיון מגוון ומתמחים
          <br />
          בעבודה מול תעשיות רבות. אתם תקבלו מאיתנו את
          <br />
          אנשי המקצוע המתאימים ביותר לעבודת התרגום
          <br />
          שלכם. מענה מותאם אישית כל צורך.


          </p>
        </div>
      </div>
    </div>
  );
};
export default CarTwo;
