import React from "react";
import CallerIcon from "assets/CallerIcon.svg";

const CarTwoNew = () => {
  return (
    <div className="bottoM w-full sm:w-10/12 flex flex-col justify-center mx-auto ">
      <div className="flex sm:flex-row flex-col gap-x-6 justify-between pt-[4rem]">
        <div className="text-center">
          <img src={CallerIcon} alt="" className="mx-auto pb-2" />
          <h3 className="text-[20px] font-semibold text-[#00173A] font-secondary">
            שירות לקוחות זמין <br />
            24/7
          </h3>
          <p className="text-[15px] font-opensans text-[#00173A] leading-loose pt-8">
            הצוות המסור שלנו עובד מסביב <br />
            לשעון. אנו זמינים לענות <br />
            לפנייתכם תוך דקות ספורות. <br />
          </p>
        </div>
      </div>
    </div>
  );
};
export default CarTwoNew;
