import React from "react";

const ThirdComp = ({
  firstHead,
  secondHead,
  thirdHead,
  forHebrew,
  contentOne,
  contentTwo,
  fourthHead,
}) => {
  return (
    <div className="bg-[#F6FCFF] py-12">
      <div
        className={`w-10/12 2xl:w-8/12 3xl:w-7/12 mx-auto flex ${
          forHebrew ? "sm:flex-row-reverse flex-col" : "sm:flex-row"
        } justify-between `}
      >
        <h2
          className={`text-[27px] sm:text-[40px] text-[#00173A] ${
            forHebrew ? "text-right" : ""
          } font-secondary font-bold md:w-[50%] text-rtl`}
        >
          <span className="text-[#00173A]">איך תהליך</span>
          <br />
          <span className="text-orange">
            התרגום &nbsp;
            <span className="text-[#00173A] text-rtl">שלנו עובד?</span>
          </span>
        </h2>
        <div
          className={`text-[18px] pt-12 sm:pt-0 font-opensans ${
            forHebrew ? "text-right text-rtl" : ""
          } leading-relaxed text-[#00173A] sm:w-[494px]`}
        >
          <p>{contentOne}</p>
          <p className="pt-6">{contentTwo}</p>
        </div>
      </div>
    </div>
  );
};

export default ThirdComp;
