import React from "react";
import Web from "../../../assets/web.svg";

const About = ({ forHebrew }) => {
  return (
    <div className="mx-auto bg-[#F6FCFF]">
      <div className="grid lg:grid-cols-2 justify-center items-center gap-16 gap-x-0 py-24 px-10">
        <div className="m-auto sm:block hidden">
          <img src={Web} alt="" className="md:w-[437px] md:h-[397px]" />
        </div>
        <div className="max-w-xl">
          <h2
            className={` ${
              forHebrew ? "text-right" : ""
            } font-secondary text-[#00173A] text-[35px] sm:text-[45px] font-semibold sm:font-bold mb-8`}
          >
            אודות טומדס
          </h2>
          <p
            className={` ${
              forHebrew ? "text-right" : ""
            } font-opensans text-[#00173A] text-lg mb-10 max-w-[41rem] text-rtl`}
          >
            עפר תירוש ייסד את טומדס בשנת 2007 עם המטרה לספק שירותי תרגום לעסקים
            המבקשים לגדול מעבר לגבולות המדינה. מאז חברת התרגום שלנו צמחה לחברה
            בינלאומית בעלת סניפים בכל רחבי העולם.
          </p>
          <div
            className={`grid grid-cols-1 gap-y-6 sm:grid-cols-1 max-w-2xl ${
              forHebrew ? "justify-items-end text-right" : ""
            }`}
          >
            {/* <div>
              <p className="font-opensans text-lg font-bold text-[#00173A] mb-4">
                UNITED STATES
              </p>
              <span className="font-opensans text-14 text-[#00173A]">
                Tomedes Ltd - USA 9450 <br />
                SW Gemini Dr #34540, <br />
                Beaverton, OR 97008-7105 <br />
                Call : +1 985 239 0142
              </span>
            </div> */}
            <div>
              <p className="font-opensans text-[26px] text-[#00173A] font-bold mb-4">
                כתובת
              </p>
              <span className="font-opensans text-[18px] text-[#00173A] text-rtl">
              ,הרוקמים 26 
                <br />
                מרכז עסקים עזריאלי חולון
                <br />
                 בניין C
                <br />
                מיקוד 5885849
                <br />
                +972 &nbsp;72&nbsp;2200700
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
