import React, { useState } from 'react'
import { useLocation } from '@reach/router'
import { useForm } from 'react-hook-form'
import Loader from 'components/loader'
import { useGetInTouchLocation } from 'hooks/Forms/useGetInTouchLocation'
import AmazonSvg from 'assets/images/amazon_1.svg'
import GoogleSvg from 'assets/images/quote_images/google_1.svg'
import MicrosoftSvg from 'assets/svg/microsoft'
import YoutubeSvg from 'assets/svg/youtubesvg'
import BloombergSvg from 'assets/ads-assets/trusted-icons/bloomberg.svg'
import SpotifySvg from 'assets/ads-assets/trusted-icons/spotify.svg'
import WixSvg from 'assets/ads-assets/trusted-icons/wix.svg'
import HsbcSvg from 'assets/svg/hsbc'
import first from 'assets/ads-assets/247.svg'
import gurantee from 'assets/ads-assets/guarantee_usp.svg'
import business from 'assets/ads-assets/business.svg'
import ThankYouPopup from 'components/popup/thankYouPopup'
import IconStar from 'assets/svg/star'
import IconTruck from 'assets/svg/truck'
import CallCenter from 'assets/patentCustomerLogos/callCenter'
import GuranteeIcon from 'assets/svg/GuranteeIcon'
import BusinessMember from 'assets/patentCustomerLogos/BusinessMember'
import formCommonPostData from 'utils/form-common-post-data'

const FirstAdComp = ({
  contentThree,
  contentFour,
  heading,
  heading2,
  FromTextColor,
  FromText1,
  FromText2,
  content,
  fontSize,
  marginTop,
  HeadfontSize,
  paddingY,
  bottomService,
  clientMobileSection,
  serviceMobileSection,
  contentTwo,
  hideContent,
  forHebrew,
}) => {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const { status, mutateAsync } = useGetInTouchLocation()

  const onSubmit = (data) => {
    const postData1 = {
      name: data.name,
      email: data.email,
      message: data.message,
      pagelink: location.href,
    }
    const postData = { ...postData1, ...formCommonPostData() }
    mutateAsync(postData).then((_) => {
      reset()
      setOpen(true)
    })
  }
  return (
    <div>
      <div
        className={`bg-banner-ads bg-no-repeat bg-cover ${
          paddingY ? paddingY : 'py-10'
        }`}
      >
        {/* -----First Component----- */}
        <div
          className={`flex flex-col  ${
            forHebrew ? 'sm:flex-row-reverse' : 'sm:flex-row'
          } sm:px-3 gap-x-12 w-11/12 mx-auto justify-center items-center 2xl:w-9/12 3xl:w-7/12 pt-24`}
        >
          <div className="flex flex-col w-[302px] sm:w-[600px]">
            <h1
              className={`text-[35px] ${
                forHebrew ? 'text-right' : ''
              } sm:px-0 ${
                HeadfontSize ? HeadfontSize : 'sm:text-[40px]'
              } font-bold font-secondary text-[#00173A] pt-8 sm:pt-0`}
            >
              {heading}
              <span>
                <br />
                {heading2}
              </span>
            </h1>
            <p
              className={`${fontSize ? fontSize : 'text-[16px]'}  ${
                forHebrew ? 'text-right mr-auto text-rtl' : ''
              } leading-relaxed ${
                hideContent ? 'hidden sm:flex' : null
              } font-opensans py-4 sm:w-[510px] text-[#00173A]`}
            >
              {content}
            </p>
            {contentTwo ? (
              <p
                className={`${
                  fontSize ? fontSize : 'text-[16px]'
                } leading-relaxed sm:hidden font-opensans py-4 sm:w-[510px] text-[#00173A] text-right text-rtl`}
              >
                {contentTwo}
              </p>
            ) : null}
            {contentThree ? (
              <div className=" flex-col gap-y-4 pt-[10px] sm:flex hidden">
                <div className="flex gap-x-2">
                  <img src={first} alt="" />
                  <span className="font-secondary text-[20px] text-[#00173A]">
                    Available Online 24/7
                  </span>
                </div>
                <div className="flex gap-x-2">
                  <img src={gurantee} alt="" />
                  <span className="font-secondary text-[20px] text-[#00173A]">
                    1-Year Guarantee
                  </span>
                </div>
                <div className="flex gap-x-2">
                  <img src={business} alt="" />
                  <span className="font-secondary text-[20px] text-[#00173A]">
                    95,000 Business Customers
                  </span>
                </div>
              </div>
            ) : null}
            {contentFour ? (
              <div className="py-[2rem] sm:flex flex-col hidden">
                <p className="text-white font-sans font-semibold text-[16px]">
                  Trusted by Satisfied Clients Worldwide
                </p>
                <div className="grid grid-cols-4 gap-6 pt-6 items-center">
                  <img src={AmazonSvg} alt="" />
                  <img src={GoogleSvg} alt="" />
                  <MicrosoftSvg />
                  <img src={WixSvg} alt="" />
                  <YoutubeSvg />
                  <img src={BloombergSvg} alt="" className="pt-[2px]" />
                  <img src={SpotifySvg} alt="" />
                  <HsbcSvg />
                </div>
              </div>
            ) : null}
          </div>
          <ThankYouPopup open={open} setOpen={setOpen} />
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={`h-full px-6 sm:px-10 pt-6 sm:pt-8 w-full sm:w-[409px] bg-[#FFFFFF] pb-6 sm:pb-8 ${
              marginTop ? marginTop : null
            }`}
            style="box-shadow: 5px 5px 50px #004C7430; border-radius: 20px;"
          >
            <h2
              className={`${
                forHebrew ? 'text-right' : ''
              } text-[20px] text-[#00173a] sm:text-[22px] font-semibold font-secondary`}
            >
              <span className="text-orange">
                {FromTextColor}
                <span className="text-[#00173a]">
                  {FromText1} <br />
                </span>
              </span>
              {FromText2}
            </h2>
            <div className="flex flex-col pt-4">
              <input
                type="text"
                name="name"
                required
                className={` ${
                  forHebrew ? 'text-right pr-2' : ''
                } pl-2 py-2 rounded mt-2 font-opensans font-light`}
                placeholder="שם"
                style="border: 1px solid #D8D8D8;"
                {...register('name', {
                  required: true,
                })}
              />
              {/* {errors.name && (
                <span className="text-red text-sm mb-[10px] font-sans mt-2">
                  This field is required.
                </span>
              )} */}
              <input
                type="email"
                placeholder="אי מייל"
                name="email"
                required
                className={` ${
                  forHebrew ? 'text-right pr-2' : ''
                } pl-2 py-2 rounded mt-2 font-opensans font-light`}
                style="border: 1px solid #D8D8D8;"
                {...register('email', {
                  required: true,
                  pattern: /^\S+@\S+$/i,
                })}
              />
              {/* {errors.email && (
                <span className="text-red text-sm font-sans mb-[10px] mt-2">
                  This field is required.
                </span>
              )} */}

              <textarea
                type="text"
                name="message"
                className={` ${
                  forHebrew ? 'text-right pr-2' : ''
                } pl-2 py-2 rounded mt-2 font-opensans font-light min-h-[153px]`}
                placeholder="ספרו לנו מה הצורך שלכם"
                style="border: 1px solid #D8D8D8;"
                {...register('message')}
              />
              {errors.message && (
                <span className="text-red text-sm font-primary mb-[10px] ">
                  This field is required
                </span>
              )}
              <button className="bg-orange text-white text-[18px] font-opensans font-semibold py-3 mt-4">
                {status === 'loading' ? <Loader /> : 'צרו קשר'}
              </button>
            </div>
            <input type="hidden" id="zc_gad" name="zc_gad" value="" />
          </form>
          {serviceMobileSection ? (
            <div className=" flex-col gap-y-4 pt-8 sm:hidden flex justify-start">
              <div className="flex gap-x-2">
                <img
                  src="https://tomedes.gumlet.io/frontend/images/location-template/call-center.svg"
                  alt=""
                />
                <span className="font-secondary text-[20px] text-white ">
                  Available Online 24/7
                </span>
              </div>
              <div className="flex gap-x-2">
                <img
                  src="https://tomedes.gumlet.io/frontend/images/location-template/badge.svg"
                  alt=""
                />
                <span className="font-secondary text-[20px] text-white">
                  1-Year Guarantee
                </span>
              </div>
              <div className="flex gap-x-2">
                <img
                  src="https://tomedes.gumlet.io/frontend/images/location-template/16-express.svg"
                  alt=""
                />
                <span className="font-secondary text-[20px] text-white">
                  95,000 Business Customers
                </span>
              </div>
            </div>
          ) : null}

          {clientMobileSection ? (
            <div className="pt-[3rem] text-white sm:hidden flex flex-col">
              <p className="text-[16px] font-opensans font-semibold text-left">
                Trusted by Satisfied Clients Worldwide
              </p>
              <div className="grid grid-cols-3 gap-8 pt-6 px-2 pr-5">
                <img src={AmazonSvg} alt="" />
                <img src={GoogleSvg} alt="" />
                <MicrosoftSvg />
                <img src={WixSvg} alt="" />
                <YoutubeSvg />
                <img src={BloombergSvg} alt="" />
                <img src={SpotifySvg} alt="" />
                <HsbcSvg />
              </div>
            </div>
          ) : null}
        </div>
        {bottomService ? (
          <div className="mx-auto">
            <div className="sm:w-11/12 2xl:w-9/12 3xl:w-7/12 mx-auto py-8 sm:flex hidden justify-center">
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-x-8 justify-items-center justify-between w-full">
                <div className="flex flex-col lg:flex-row-reverse items-start gap-y-3 gap-x-2">
                  <CallCenter />
                  <span className="text-white font-opensans font-semibold text-base text-right">
                    מענה אנושי
                    <br />
                    24/7
                    {/* Available <br /> Online 24/7 */}
                  </span>
                </div>
                <div className="flex flex-col lg:flex-row-reverse items-start gap-y-3 gap-x-2">
                  <GuranteeIcon />
                  <span className="text-white font-opensans font-semibold text-base">
                    שנה אחריות
                  </span>
                </div>
                <div className="flex flex-col lg:flex-row-reverse items-start gap-y-3 gap-x-2">
                  <BusinessMember />
                  <span className="text-white font-opensans font-semibold text-base text-right">
                    95,000 <br />
                    לקוחות מרוצים
                  </span>
                </div>
                <div className="flex flex-col lg:flex-row-reverse items-start gap-y-3 gap-x-2">
                  <IconTruck />
                  <span className="text-white font-opensans font-semibold text-base">
                    שירות מהיר
                    {/* Fast <br /> Delivery */}
                  </span>
                </div>
                {/* <div className="flex flex-col lg:flex-row items-start gap-y-3 gap-x-2">
                  <IconStar />
                  <span className="text-white font-opensans font-semibold text-base">
                    כוכבים. הוא 4.8 מתוך 5
                    <br />
                    שיעור שביעות הרצון שלנו
                  </span>
                </div> */}
              </div>
            </div>

            {/* ----------mobile---------- */}
            <div className="sm:hidden grid grid-cols-2 gap-y-10 gap-x-5 py-8">
              <div className="flex flex-col items-center gap-y-3">
                <img
                  src="https://tomedes.gumlet.io/frontend/images/location-template/call-center.svg"
                  alt=""
                  className="w-[28px] h-[28px]"
                />
                <div className="text-center text-white font-opensans font-semibold text-base">
                  <span>
                    מענה אנושי
                    <br />
                    24/7
                  </span>
                </div>
              </div>
              <div className="flex flex-col items-center gap-y-3">
                <BusinessMember />
                <span className="text-white font-opensans font-semibold text-base text-center">
                  95,000 <br />
                  לקוחות מרוצים
                </span>
              </div>
              <div className="flex flex-col items-center gap-y-3">
                <img
                  src="https://tomedes.gumlet.io/frontend/images/location-template/badge.svg"
                  alt=""
                  className="w-[22px] h-[28px]"
                />
                <span className="text-white font-opensans font-semibold text-base text-center">
                  שנה אחריות
                </span>
              </div>
              <div className="flex flex-col items-center gap-y-3">
                <IconTruck />
                <span className="text-white font-opensans font-semibold text-base text-center">
                  שירות מהיר
                </span>
              </div>
            </div>

            {/* ----------mobile---------- */}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default FirstAdComp
